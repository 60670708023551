exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-knife-knife-pop-js": () => import("./../../../src/pages/games/knife-knife-pop.js" /* webpackChunkName: "component---src-pages-games-knife-knife-pop-js" */),
  "component---src-pages-games-tic-tac-cube-js": () => import("./../../../src/pages/games/tic-tac-cube.js" /* webpackChunkName: "component---src-pages-games-tic-tac-cube-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-work-for-hire-js": () => import("./../../../src/pages/work-for-hire.js" /* webpackChunkName: "component---src-pages-work-for-hire-js" */)
}

